/* Built In Imports */

/* External Imports */

/**
 * Renders the Style Config on sectionId
 *
 * @param {object} PageConfig- Data for section
 * @param {object} children - Child Elements
 * @param {string} id - id
 * @returns {ReactElement} PageConfig for children component
 */

const PageConfig = ({
  pageData,
  isLikeVisible = false,
  direction = 'row',
  position,
  // top = '60%',
  likesCount = 0,
  shareTextColor,
  hideInMobile = false,
}) => {
  return (
    <>
      {pageData?.pageConfig?.length > 0 &&
        pageData?.pageConfig?.map((config, i) => {
          if (
            !config?.value?.hasOwnProperty('msr_sticky_social') ||
            config?.value?.msr_sticky_social === true
          ) {
            return null;
          }
        })}
    </>
  );
};

export default PageConfig;

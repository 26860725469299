/* Built In Imports */
import React, { memo } from 'react';

/* External Imports */
import {
  Box,
  Flex,
  Link,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useToast,
} from '@chakra-ui/react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BiShareAlt } from 'react-icons/bi';
import { FaFacebookF, FaLinkedin, FaTwitter, FaWhatsapp } from 'react-icons/fa';
import { TbCopy, TbMail, TbX } from 'react-icons/tb';

/* Internal Imports */
/* Components */
import config from '@config';

/* Styles */
/* Services */

/**
 *
 * @param {string} background
 * @returns
 */

const ShareIcon = ({ background, url, icon, display }) => {
  // const isMobile = useMediaQuery({ maxWidth: 766 });
  // const [visible, setIsVisible] = useState(false);
  // const [isCopied, setIsCopied] = useState(false);
  const toast = useToast();
  const initRef = React.useRef();
  const socLink = 'https://www.addtoany.com/add_to';
  return (
    <Box pos="relative" display={display || 'auto'}>
      <Popover placement="bottom-end" initialFocusRef={initRef}>
        {({ isOpen }) => (
          <>
            <PopoverTrigger zIndex="12" pos="relative">
              <Flex
                width={{ base: '30px', md: '38px' }}
                height={{ base: '30px', md: '38px' }}
                background={{
                  base: isOpen ? '#A9392D' : background || 'transparent',
                  md: isOpen ? '#A9392D' : background || 'transparent',
                }}
                rounded="full"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                fontSize="16px"
                color="#F5D5A9"
                // _hover={{ color: '#F6A15E' }}
                pos="relative"
                zIndex="11"
              >
                {isOpen && (
                  <Text fontWeight="light" as="span" fontFamily="monospace">
                    <TbX />
                  </Text>
                )}
                {!isOpen && (
                  <Box as="span" ml="-2px" mt="1px">
                    {!icon && <BiShareAlt />}
                    {icon && (
                      <Text
                        color="buttonText.color1"
                        fontSize={{ base: '20px', md: '25px' }}
                        fontWeight="bold"
                      >
                        <BiShareAlt />
                      </Text>
                    )}
                  </Box>
                )}
              </Flex>
            </PopoverTrigger>
            <PopoverContent
              height="auto"
              w={{ base: '30px', md: '38px' }}
              outline="none"
              _hover={{ outline: 'none' }}
              _focus={{ outline: 'none' }}
              rounded="md"
              boxShadow="0px 20px 13px rgba(90, 63, 9, 0.035), 0px 8.14815px 6.51852px rgba(90, 63, 9, 0.0274815), 0px 1.85185px 3.14815px rgba(90, 63, 9, 0.0168519)"
              background="common.white"
              border="1px solid #EDD1C2"
              mt="-22px"
            >
              <PopoverBody
                boxSizing="border-box"
                padding="10px"
                background="#ffffff"
                minH={{ base: '1px', md: '205px' }}
                height="fit-content"
                rounded="md"
                zIndex={{ base: '10', md: '8' }}
              >
                <Flex
                  flexDir="column"
                  pt="10px"
                  // _hover={{ color: '#BC4034' }}
                  gap={{ base: '11px', md: '15px' }}
                  color="#D47A33"
                  height="auto"
                  alignItems="center"
                  justifyContent="center"
                  fontSize={{ base: '12px', md: 'body2' }}
                  gridGap={{ base: '11px', md: '15px' }}
                  // style={{
                  //   gap: useBreakpointValue({ base: '11px', md: '15px' }),
                  // }}
                >
                  <Box _hover={{ color: '#BC4034' }}>
                    {/* <WhatsappShareButton
                      url={`${config.BASE_PATH}${url}`}
                      title={''}
                      separator=":: "
                    > */}
                    <Link
                      target="_blank"
                      href={`${socLink}/whatsapp?linkurl=${config.BASE_PATH}${url}`}
                    >
                      <FaWhatsapp />
                    </Link>
                    {/* </WhatsappShareButton> */}
                  </Box>
                  <Box _hover={{ color: '#BC4034' }}>
                    {/* <FacebookShareButton
                      url={`${config.BASE_PATH}${url}`}
                      quote={''}
                    > */}
                    <Link
                      target="_blank"
                      href={`${socLink}/facebook?linkurl=${config.BASE_PATH}${url}`}
                    >
                      <FaFacebookF />
                    </Link>
                    {/* </FacebookShareButton> */}
                  </Box>
                  <Box _hover={{ color: '#BC4034' }}>
                    {/* <LinkedinShareButton
                      url={`${config.BASE_PATH}${url}`}
                      quote={''}
                      title=""
                      summary=""
                      source=""
                    > */}
                    <Link
                      target="_blank"
                      href={`${socLink}/linkedin?linkurl=${config.BASE_PATH}${url}`}
                    >
                      <FaLinkedin borderRadius="50%" />
                    </Link>
                    {/* </LinkedinShareButton> */}
                  </Box>
                  <Box _hover={{ color: '#BC4034' }}>
                    {/* <TwitterShareButton
                      href={`${config.BASE_PATH}${url}`}
                      title=""
                      quote={''}
                    > */}
                    <Link
                      target="_blank"
                      href={`${socLink}/twitter?linkurl=${config.BASE_PATH}${url}`}
                    >
                      <FaTwitter />
                    </Link>
                    {/* </TwitterShareButton> */}
                  </Box>
                  <Box _hover={{ color: '#BC4034' }}>
                    {/* <EmailShareButton
                      url={`${config.BASE_PATH}${url}`}
                      title={''}
                      separator=":: "
                      subject=""
                      body=""
                    > */}
                    <Link
                      href={`${socLink}/email?linkurl=${config.BASE_PATH}${url}`}
                      target="_blank"
                    >
                      <TbMail />
                    </Link>
                    {/* </EmailShareButton> */}
                  </Box>
                  <Box _hover={{ color: '#BC4034' }}>
                    <CopyToClipboard
                      text={`${config.BASE_PATH}${url}`}
                      onCopy={() =>
                        toast({
                          title: 'Link copied',
                          description: 'Link copied',
                          // status: 'secondary',
                          duration: 3000,
                          isClosable: false,
                          colorScheme: 'gray',
                          render: () => (
                            <Box
                              background="#1f1f1f"
                              fontFamily="FedraSansStd-book"
                              fontSize="18px"
                              fontWeight="350"
                              padding="10px"
                              color="white"
                              width="150px"
                              border="1px solid #EFEFEF"
                              textAlign="center"
                              borderRadius="4px"
                            >
                              Link copied
                            </Box>
                          ),
                        })
                      }
                    >
                      <span>
                        <TbCopy cursor="pointer" />
                      </span>
                    </CopyToClipboard>
                  </Box>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </>
        )}
      </Popover>
    </Box>
  );
};
export default memo(ShareIcon);

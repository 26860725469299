/* Built In Imports */
import dynamic from 'next/dynamic';
import { Children, cloneElement } from 'react';

/* External Imports */
import { Box, Heading } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';
import { render as toPlainText } from 'datocms-structured-text-to-plain-text';
import {
    isBlockquote,
    isHeading,
    isListItem,
    isParagraph,
    renderRule,
} from 'datocms-structured-text-utils';
import { nanoid } from 'nanoid';
import { StructuredText } from 'react-datocms';

/* Internal Imports */
/* Components */
import AdImageBanner from '@lb/components/Banners/Ads/AdImageBanner';
import SadhguruSignatureLoveGrace from '@lb/components/Blogs/SadhguruSignatureLoveGrace';
import SimpleButton from '@lb/components/Buttons/SimpleButton';
import LbBlogImageAtStart from '@lb/components/Cards/LbBlogImageAtStart';
import Quote from '@lb/components/Cards/Quote';
import SingleImage from '@lb/components/Cards/SingleImage';
import BlogTableOfContentIndex from '@lb/components/Table/BlogTableOfContentIndex';
import BlogTableOfContentsSection from '@lb/components/Table/BlogTableOfContentsSection';
import JsonTableUI from '@lb/components/Table/JsonTableUI';
import BlogPoem from '@lb/components/UI/BlogPoem';
import StructuredTextParser from '@lb/components/Utility/StructuredTextParser';
import { refineUrlDomain } from '@lb/components/Utility/utils';

/* Services */
/* Styles */
import classes from '@lb/components/Utility/STstyle.module.css';

/**
 * Renders Structured Text Content Component
 *
 * @param {object} mainContent - data
 * @param region
 * @param language
 * @param pType
 * @param pageData
 * @returns {ReactElement} Structured Text Content component
 */
export default function StructuredTextContent(
  mainContent,
  region,
  language,
  pType,
  pageData
) {
  const contentValue = mainContent;

  return (
    <StructuredText
      data={contentValue}
      customRules={[
        renderRule(isHeading, ({ node, children, key }) => {
          const HeadingTag = `h${node.level}`;
          const anchor = toPlainText(node)
            ?.toLowerCase()
            .replace(/ /g, '-')
            .replace(/[^\w-]+/g, '');
          if (node.level == 2) {
            return (
              <Box
                fontSize={32}
                key={nanoid()}
                color="#65000F"
                pt="40px"
                fontFamily="FedraSansStd-A-medium"
                mx="auto"
                maxW="696px"
                width="100%"
              >
                <HeadingTag>{children}</HeadingTag>
              </Box>
            );
          } else {
            return (
              <Box
                key={nanoid()}
                className={classes.LBStructured}
                mx="auto"
                maxW="696px"
                width="100%"
              >
                <HeadingTag>{children}</HeadingTag>
              </Box>
            );
          }
        }),
        renderRule(
          isParagraph,
          ({ adapter: { renderNode }, key, children }) => {
            if (pType === 'blog') {
              return (
                <Box
                  as="p"
                  w="100%"
                  // maxW={pType === 'blog' ? '667px' : '696px'}
                  maxW={696}
                  pr={0}
                  // px={{ base: '15px', md: '0' }}
                  mx="auto"
                  className={classes.LBStructured}
                >
                  {children}
                </Box>
              );
            }

            const arrayChildren = Children.toArray(children);
            return (
              <Box
                as="p"
                w="100%"
                // maxW={pType === 'blog' ? '667px' : '696px'}
                maxW={696}
                pr={0}
                // px={{ base: '15px', md: '0' }}
                mx="auto"
                // className={classes.LBStructured}
              >
                {' '}
                {Children.map(arrayChildren, (child) => {
                  if (child.type === 'a') {
                    return (
                      <>
                        <a
                          href={refineUrlDomain(
                            child.props.href,
                            region,
                            language
                          )}
                          key={nanoid()}
                        >
                          {cloneElement(child.props.children[0])}
                        </a>
                      </>
                    );
                  }
                  return <>{child}</>;
                })}
              </Box>
            );
          }
        ),
        renderRule(isListItem, ({ adapter: { renderNode }, key, children }) => {
          return (
            <li
              className={classes.LBStructured}
              style={{ maxWidth: '656px', margin: '0 auto', width: '100%' }}
            >
              {children}
            </li>
          );
        }),
        renderRule(
          isBlockquote,
          ({ adapter: { renderNode }, key, node, children }) => {
            if (pType === 'blog') {
              return (
                <Box
                  w="100%"
                  maxW="810px"
                  m={{ base: '30px auto', md: '60px auto' }}
                >
                  <Quote
                    sectionContent={{ quote: render(node) }}
                    region={region}
                    lang={language}
                  />
                </Box>
              );
            } else if (pType === 'ritual') {
              return (
                <Quote
                  sectionContent={{ quote: render(node) }}
                  region={region}
                  lang={language}
                />
              );
            } else {
              const childrenWithAttribution = node.attribution
                ? [
                    ...(children || []),
                    renderNode('span', { key: 'span' }, node.attribution),
                  ]
                : children;
              return renderNode('blockquote', { key }, childrenWithAttribution);
            }
          }
        ),
      ]}
      renderBlock={({ record }) => {
        switch (record.__typename) {
          case 'SimpleButtonRecord':
            return (
              <Box
                w="100%"
                maxW={pType === 'blog' ? '667px' : '696px'}
                display="grid"
                m="0 auto"
              >
                <SimpleButton style={record} region={region} lang={language} />
              </Box>
            );
          case 'NewsletterSubscriptionFormRecord':
            const ArticlesSubscribeBox = dynamic(() =>
              import('@lb/components/Subscribe/ArticlesSubscribeBox')
            );
            return (
              <Box m="60px 0">
                <ArticlesSubscribeBox
                  region={region}
                  language={language}
                  sectionContent={record}
                />
              </Box>
            );
          case 'MediaEmbedV2Record':
            const mediaSchemaData = {
              '@context': 'https://schema.org',
              '@type': 'VideoObject',
              name: record?.video?.title,
              description: record?.video?.title,
              thumbnailUrl: record?.video?.thumbnailUrl,
              uploadDate: pageData.createdAt,
              embedURL: record?.video?.url,
              author:
                pageData.authorStructuredData === 'Sadhguru'
                  ? [
                      {
                        '@type': 'Person',
                        name: 'Sadhguru Jaggi Vasudev',
                        url: 'https://isha.sadhguru.org/us/en',
                        sameAs: 'https://www.instagram.com/sadhguru/?hl=en',
                        identifier: 'https://www.youtube.com/c/sadhguru',
                      },
                    ]
                  : [
                      {
                        '@type': 'Organization',
                        name: 'Isha Foundation',
                        url: 'https://www.instagram.com/isha.foundation/?hl=en',
                        logo: '',
                        sameAs: [
                          'https://www.facebook.com/ishafoundation/',
                          'https://twitter.com/ishafoundation',
                          'https://www.instagram.com/isha.foundation/?hl=en',
                          'https://www.youtube.com/channel/UCgaiWfiix1zaQS6Mn5SIw2g',
                        ],
                      },
                    ],
            };
            const MediaEmbedV2 = dynamic(() =>
              import('@lb/components/Embeds/MediaEmbedV2')
            );
            return (
              <>
                <MediaEmbedV2
                  sectionContent={record}
                  margin={{ base: '40px 0', md: '60px 0' }}
                />
                <script
                  type="application/ld+json"
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify(mediaSchemaData),
                  }}
                />
              </>
            );
          case 'MediaEmbedRecord':
            const MediaEmbed = dynamic(() =>
              import('@lb/components/Embeds/MediaEmbed')
            );
            return (
              <Box my={{ base: '40px', md: '60px' }}>
                <MediaEmbed sectionContent={record} />
              </Box>
            );
          case 'JsonTableUiRecord':
            return (
              <Box my={10}>
                <JsonTableUI
                  sectionContent={record}
                  region={region}
                  lang={language}
                />
              </Box>
            );
          case 'StructuredTextRecord':
            if (record.style === 'lb-blog-image-at-start') {
              return (
                <Box>
                  <LbBlogImageAtStart
                    sectionContent={record}
                    record={record}
                    region={region}
                    lang={language}
                  />
                </Box>
              );
            } else {
              return (
                <Box
                  sx={{
                    h2: {
                      fontSize: '26px',
                    },
                    h3: {
                      fontSize: '18px',
                    },
                  }}
                  px={{ base: '15px', md: '0' }}
                  pt={0}
                  pb={{ base: '20px', md: '20px' }}
                  w="100%"
                  maxW="696px"
                  m="0 auto"
                >
                  <StructuredTextParser
                    str={render(record.body)}
                    region={region}
                    lang={language}
                    textAlign={record?.style}
                    sectionContent={record}
                  />
                </Box>
              );
            }

          case 'BlogTableOfContentIndexRecord':
            return (
              <BlogTableOfContentIndex
                sectionContent={record}
                region={region}
                lang={language}
              />
            );
          case 'BlogTableOfContentsSectionRecord':
            return (
              <BlogTableOfContentsSection
                sectionContent={record}
                region={region}
                lang={language}
              />
            );
          case 'SadhguruSignatureLoveGraceRecord':
            return (
              <Box w="100%" maxW="667px" m="0 auto">
                <SadhguruSignatureLoveGrace
                  card={record}
                  region={region}
                  lang={language}
                />
              </Box>
            );
          case 'SingleImageRecord':
            const schemaData = {
              '@context': 'https://schema.org',
              '@type': 'ImageObject',
              '@id': nanoid(4),
              name: record?.image?.alt,
              url: record?.image?.url,
              contentSize: record?.image?.size,
              datePublished: record?.image?._createdAt,
            };
            return (
              <Box
                w="100%"
                maxW="696px"
                margin={{ base: '0px auto', md: '0px auto' }}
              >
                <SingleImage
                  px="0"
                  my="0"
                  sectionContent={record}
                  region={region}
                  lang={language}
                  schemaData={schemaData}
                />
                <script
                  type="application/ld+json"
                  dangerouslySetInnerHTML={{
                    __html: JSON.stringify(schemaData),
                  }}
                />
              </Box>
            );
          case 'AdImageBannerRecord':
            return (
              <Box w="100%" maxW="696px" m="0 auto">
                <AdImageBanner
                  sectionContent={record}
                  region={region}
                  lang={language}
                />
              </Box>
            );
          case 'SectionTitleRecord':
            return (
              <Heading
                as="h2"
                fontFamily="FedraSansStd-A-medium"
                fontSize="1.7rem"
                fontWeight="normal"
                color="#02026c"
                textAlign="center"
                bgRepeat="no-repeat"
                backgroundPosition="bottom"
                mt="3rem"
                mb="1.5rem"
                pb="1.5rem"
              >
                {record.titleText}
              </Heading>
            );
          default:
            if (record._modelApiKey === 'poem') {
              return (
                <BlogPoem
                  sectionContent={record}
                  region={region}
                  lang={language}
                />
              );
            }
            return null;
        }
      }}
    />
  );
}

/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';

/* External Imports */
import {
    Box,
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
} from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
import { LangStyle } from 'constants/index';
/* Components */
import config from 'config';

/* Services */

/**
 * Renders the Breadcrum component
 *
 * @param {string} page page name
 * @param {string} alsoin languages
 * @param {string} region country
 * @param {string} lang language
 * @returns {ReactElement} Breadcrum component
 */

const Breadcrum = ({ page, region, lang, pageConfig }) => {
  const router = useRouter();

  let isVisible = false;
  if (
    pageConfig?.hasOwnProperty('breadcrumb_hide') &&
    pageConfig.breadcrumb_hide === false
  ) {
    isVisible = true;
  }

  const schemaData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [],
  };

  page?.map((item, i) => {
    schemaData.itemListElement.push({
      '@type': 'ListItem',
      position: i + 1,
      item:
        i === page.length - 1
          ? `${config.BASE_PATH}${router.asPath}`
          : `${config.BASE_PATH}${item.link}`,
      name: item.title,
    });
  });

  return (
    isVisible &&
    page?.length > 0 && (
      <>
        <Box
          display={{ base: 'none', lg: 'flex' }}
          fontFamily="FedraSerifAStdBook"
          fontSize="13px"
          letterSpacing=".2px"
          color="#FFF7E9"
          pos="relative"
          w="100%"
          background="#EFEFEF"
          p={{
            base: '3px 30px',
            lg: '6px calc((100% - 1134px) / 2) 7px calc((100% - 1134px) / 2)',
          }}
        >
          <Box
            float={
              LangStyle[lang]?.alignRTL ? LangStyle[lang]?.alignRTL : 'left'
            }
          >
            <Breadcrumb
              textOverflow="ellipsis"
              overflow="hidden"
              whiteSpace="nowrap"
              p={{ md: '0 0 0 10px', lg: '0 0 0 21px' }}
              fontWeight="medium"
              fontSize="0.8rem"
              display={{ base: 'none', md: 'block', lg: 'block' }}
              separator={'/'}
              color="#000000"
            >
              {page?.map((data, index, { length }) => {
                let link = data?.link;
                return (
                  <BreadcrumbItem key={nanoid()}>
                    {index + 1 < length && link && (
                      <NextLink href={link || '#'} passHref prefetch={false}>
                        {/* <Link cursor="pointer"> */}
                        <BreadcrumbLink
                          _hover={{
                            color: '#000000',
                            textDecoration: 'underline',
                          }}
                          fontFamily="FedraSerifAStdBook"
                          p="0 2px"
                          fontSize="13px"
                          textTransform="capitalize"
                          pointerEvents="pointer"
                          color="#000000"
                        >
                          {data?.title}
                        </BreadcrumbLink>
                        {/* </Link> */}
                      </NextLink>
                    )}
                    {index + 1 === length && (
                      <BreadcrumbLink
                        color="#A33A2C"
                        _hover={{ textDecoration: 'none' }}
                        fontFamily="FedraSerifAStdBook"
                        p="0 2px"
                        fontSize="13px"
                        textTransform="capitalize"
                        pointerEvents="none"
                      >
                        {data?.title}
                      </BreadcrumbLink>
                    )}
                  </BreadcrumbItem>
                );
              })}
            </Breadcrumb>
          </Box>
        </Box>

        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
        />
      </>
    )
  );
};

export default Breadcrum;

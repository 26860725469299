/* Built In Imports */
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

/* External Imports */
import { Box, Button, Link, Text } from '@chakra-ui/react';
import forEach from 'lodash/forEach';
import { useCookies } from 'react-cookie';

/* Internal Imports */
/* Components */
import config from '@config';
import { refineUrlDomain } from '@lb/components/Utility/utils';

/* Services */

/**
 * Returns the Simple Button.
 *
 * @param style.style
 * @param {object} style - Style for button.
 * @param {string} bType - Button Type.
 * @param {string} region - Current User Country.
 * @param {string} language -
 * @param style.clickHandler
 * @param style.padding
 * @param style.textAlign
 * @param style.width
 * @param style.mb
 * @param style.mt
 * @param style.fontstyle
 * @param style.fontbold
 * @param style.extraDomains
 * @param style.disabled
 * @param style.bType
 * @param style.region
 * @param style.lang
 * @param style.target
 * @param style.minH
 * @param style.mx
 * @returns {ReactElement} SimpleButton component.
 */
export default function SimpleButton({
  style,
  bType,
  region,
  lang,
  clickHandler,
  padding,
  textAlign,
  width,
  mb,
  mt,
  fontstyle,
  mx,
  target,
  disabled = false,
  minH,
  ...props
}) {
  const router = useRouter();
  const [currentUrl, setCurrentUrl] = useState('');
  const cookieParams = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_content',
    'utm_term',
  ];
  const [cookies, setCookie] = useCookies(cookieParams);

  useEffect(() => {
    if (!router.isReady) return;
    setCurrentUrl(refineUrlDomain(style?.linkUrl, region, lang));
    setTimeout(function () {
      forEach(cookieParams, function (cookie) {
        if (router.query[cookie]) {
          setCookie(cookie, router.query[cookie], {
            path: '/',
            maxAge: 3600 * 24, // Expires after 1year
            sameSite: true,
            domain: config.COOKIE_DOMAIN,
            secure: true,
          });
        }
      });
    }, 0);
  }, [router.isReady, style?.linkUrl]);

  const RenderButton = ({ style }) => {
    const buttonStyle = {
      'LB-Sticky-Mobile': (
        <Box
          display="grid"
          placeItems="center"
          m="0 auto"
          alignItems="center"
          position={{ base: 'fixed', md: 'static' }}
          bottom="0"
          right="0"
          bgColor="white"
          background={{ base: '#ffffff', md: 'transparent' }}
          zIndex="10"
          p="17px 36px"
        >
          <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
            <Link
              mx="auto"
              w="100%"
              // width={width || '223px'}
              // minW="303px"
              variant="primary"
              textDecor="none"
              _hover={{ textDecor: 'none' }}
              _focus={{ textDecor: 'none' }}
              _active={{ textDecor: 'none' }}
              target={
                currentUrl?.indexOf('http') > -1 &&
                  currentUrl?.indexOf(`${config.cdnPath}/linga-bhairavi`) < 0
                  ? '_blank'
                  : ''
              }
              {...props}
            >
              <Button
                // width="100%"
                variant="primary"
                size="lg"
                letterSpacing=".9px"
                fontWeight="300"
                w="auto"
                h="auto"
                minH="52px"
                py="12px"
                // maxW={{ base: '303px', md: '100%' }}
                _hover={{ textDecoration: 'none' }}
                _focus={{ textDecoration: 'none' }}
                _active={{ textDecoration: 'none' }}
              >
                <Text
                  as="span"
                  // w={{ base: '300px', md: 'auto' }}
                  // whiteSpace={{ base: 'nowrap', md: 'nowrap' }}
                  // overflow={{ base: 'hidden', md: 'unset' }}
                  // textOverflow={{ base: 'ellipsis', md: 'none' }}
                  whiteSpace="break-spaces"
                  letterSpacing=".9px"
                >
                  {style?.buttonText}
                </Text>
              </Button>
            </Link>
          </NextLink>
        </Box>
      ),
      'LB-Default': (
        <Box display="grid" placeItems="center">
          <NextLink href={disabled ? '#' : currentUrl} passHref legacyBehavior>
            <Link
              mx="auto"
              mb="30px"
              width={width}
              variant="primary"
              textDecoration="none"
              _hover={{ textDecoration: 'none' }}
              _focus={{ textDecoration: 'none' }}
              _active={{ textDecoration: 'none' }}
              target={
                target ||
                  (currentUrl?.indexOf('http') > -1 &&
                    currentUrl?.indexOf(config.cdnPath) < 0)
                  ? '_blank'
                  : ''
              }
              {...props}
            >
              <Button
                variant="primary"
                _hover={{ textDecoration: 'none' }}
                _focus={{ textDecoration: 'none' }}
                _active={{ textDecoration: 'none' }}
                size="lg"
                fontWeight="300"
                whiteSpace="break-spaces"
                letterSpacing=".9px"
                height="auto"
                minH="52px"
                py="12px"
              >
                {style?.buttonText}
              </Button>
            </Link>
          </NextLink>
        </Box>
      ),
    };
    return buttonStyle[style?.buttonStyle] || null;
  };

  return (
    <Box
      boxSizing="borderBox"
      display={bType ? 'flex' : 'grid'}
      placeItems={'left'}
      as={'button'}
      color="#faf7f0"
      width={'auto'}
      maxW={{ base: '300px', sm: '450px', md: '500px' }}
      h="auto"
      borderRadius="5px"
      fontSize={'18px'}
      textAlign="center"
      justifyContent={bType ? 'center' : ''}
      cursor="default"
      mb={(style?.buttonStyle === 'LB-Sticky-Mobile' && '0') || mb || '30px'}
      minH={
        (style?.buttonStyle === 'LB-Sticky-Mobile' && '0') || minH || '50px'
      }
      mt={
        (style?.buttonStyle === 'LB-Sticky-Mobile' && '0') || mt ? mt : '12px'
      }
      // backgroundImage="/assets/images/lb-white-texture-bg.png"
      background="transparent"
      mx={mx}
    >
      {!bType && currentUrl ? <RenderButton style={style} /> : null}
    </Box>
  );
}

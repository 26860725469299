/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Image } from '@chakra-ui/react';

/* Components */
/* Styles */
/* Services */

/**
 * @param {string} url - src of image
 * @param {object} props
 * @returns {ReactElement} ResImg
 */
const ResImg = ({ url, speed, ...props }) => {
  // console.log(url, 'speed');
  const [imgUrl, setImgUrl] = useState(url);
  let networkObj = {
    '4g': '?q=100',
    '3g': '?q=50',
    '2g': '?q=20',
  };
  
  useEffect(() => {
    if (speed) {
      setImgUrl(`${url}${networkObj[speed]}`);
    }
  }, [speed]);

  return (
    <>
      {/* <p>speed {speed}</p>

      <p>{imgUrl}</p> */}
      <Image src={imgUrl} {...props} />
    </>
  );
};

export default ResImg;

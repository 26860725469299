/* Built In Imports */
import NextLink from 'next/link';

/* External Imports */
import { Box, Link } from '@chakra-ui/react';
import { useMediaQuery } from 'react-responsive';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import { refineUrlDomain } from '@lb/components/Utility/utils';

/* Styles */
/* Services */
/**
 * return AdImageBanner Banner
 *
 * @param sectionContent
 * @returns {ReactElement} - AdImageBanner
 */

const AdImageBanner = ({ sectionContent, region, lang, width, height, my }) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <Box maxW="1330px" width="100%" mx="auto">
      <Box
        textAlign="center"
        position="relative"
        mx="auto"
        my={my || 10}
        width={width || '100%'}
        height={height || 'auto'}
      >
        <NextLink
          href={
            sectionContent?.linkUrl
              ? refineUrlDomain(sectionContent?.linkUrl, region, lang)
              : 'javascript:;'
          }
          passHref
          legacyBehavior>
          <Link
            target={
              sectionContent?.linkUrl?.indexOf('http') > -1 &&
              sectionContent?.linkUrl.indexOf(config.cdnPath) < 0
                ? '_blank'
                : ''
            }
          >
            <LazyLoadImageComponent
              alt={
                sectionContent?.image?.title ||
                sectionContent?.image?.alt ||
                sectionContent?.mobileImage?.alt ||
                ''
              }
              title={sectionContent?.image?.title}
              textAlign="center"
              src={
                isMobile
                  ? sectionContent?.mobileImage?.url ||
                    sectionContent?.image?.url
                  : sectionContent?.image?.url
              }
              height="auto"
              w="100%"
            />
          </Link>
        </NextLink>
      </Box>
    </Box>
  );
};

export default AdImageBanner;

/* Built In Imports */
import { useEffect, useState } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Components */
import ResImg from '@lb/components/Img/ResImage';

/* Styles */
/* Services */

/**
 *
 * @param {string} url - image src
 * @param {object} props
 * @returns {ReactElement} Speed Res Image component
 */

const SpeedResImage = ({ url, ...props }) => {
  let startTime, endTime, network;
  let imageSize = '';
  const [bitOutput, setBitOutput] = useState(null);
  const [kbOutput, setKbOutput] = useState(null);
  const [mbOutput, setMbOutput] = useState(null);
  let image = null;
  let imageLink = url;
  
  useEffect(() => {
    image = new Image();
    image.onload =
      !sessionStorage.getItem('network') &&
      async function () {
        endTime = new Date().getTime();
        await fetch(imageLink).then(response => {
          imageSize = response.headers.get('content-length');
          calculateSpeed();
        });
      };
  }, []);
  /**
   *
   */
  function calculateSpeed() {
    let timeDuration = (endTime - startTime) / 1000;
    let loadedBits = imageSize * 8;
    let speedInBps = (loadedBits / timeDuration).toFixed(2);
    let speedInKbps = (speedInBps / 1024).toFixed(2);
    let speedInMbps = (speedInKbps / 1024).toFixed(2);
    setBitOutput(speedInBps);
    setKbOutput(speedInKbps);
    setMbOutput(speedInMbps);
  }
  const init = async () => {
    startTime = new Date().getTime();
    image.src = imageLink;
  };

  useEffect(() => {
    if (!sessionStorage.getItem('network')) {
      init();
    }
  }, []);

  let kb = Math.round(kbOutput);
  network = kb < 3000 ? '2g' : kb >= 4000 ? '4g' : '3g';

  /**
   *
   * @param network
   */
  function setSession(network) {
    if (network) {
      if (!sessionStorage.getItem('network')) {
        sessionStorage.setItem('network', network);
      }
    }
  }

  useEffect(() => {
    setSession(network);
  }, []);

  return (
    <Box as="section">
      {/* <Box
        className="container"
        w="350px"
        backgroundColor="#fff"
        padding="4em 2em"
        position="absolute"
        transform="translate(-50%, -50%)"
        top="50%"
        left="50%"
        rounded="full"
        fontSize="1.2em"
        lineHeight="2.2em"
        box-shadow="0 1.6em 2.4em rgb(40, 4, 67, 0.3)"
        fontFamily="sans-serif"
      >
        <span>
          <span>Speed in Bits:</span> {bitOutput}
        </span>
        <span>
          <span>Speed in Kbs:</span> {kbOutput}
        </span>
        <span>
          <span>Speed in Mbs:</span> {mboutput}
        </span> */}
      {/* {sessionStorage && (
        <ResImg
          url={url}
          speed={sessionStorage && sessionStorage?.getItem('network') || network}
          {...props}
        />
      )} */}
      
      {/* {sessionStorage.getItem('network') || network} */}
      {/* </Box> */}
    </Box>
  );
};

export default SpeedResImage;

/* External Imports */
import { Box } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import SpeedResImage from '@lb/components/Img/SpeedResImage';
import MidContainer from '@lb/components/Layout/MidContainer';

/**
 * Renders the SingleImage Card component
 *
 * @param {object} sectionContent - Data for section
 * @param {string} region - Region for page
 * @param {Sting} lang - Language for page
 * @param my
 * @param px
 * @param w
 * @param h
 * @param maxWidth
 * @param mx
 * @returns {ReactElement} SingleImage Card component
 */

const SingleImage = ({
  sectionContent,
  region,
  lang,
  my,
  px,
  w,
  h,
  maxWidth,
  mx,
  schemaData,
}) => {
  const imgSchemaData = schemaData
    ? schemaData
    : {
        '@context': 'https://schema.org',
        '@type': 'ImageObject',
        '@id': nanoid(4),
        name: sectionContent?.image?.alt,
        url: sectionContent?.image?.url,
        contentSize: sectionContent?.image?.size,
        datePublished: sectionContent?.image?._createdAt,
      };

  return sectionContent.image?.url ? (
    <MidContainer>
      <Box
        textAlign="center"
        position="relative"
        mx={'auto'}
        my={my || 0}
        px={{ base: px || '5px', md: '0' }}
        className="image-container"
        // maxW={{ base: 302, sm: 330, lg: 470 }}
      >
        <SpeedResImage
          h={h || '100%'}
          alt={sectionContent.image?.alt}
          title={sectionContent.image?.title}
          textAlign="center"
          url={sectionContent?.image?.url}
          w={w || '100%'}
          maxWidth={maxWidth || '100%'}
          mx={{ sm: mx || '0', lg: mx || '0' }}
        />
      </Box>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(imgSchemaData) }}
      />
    </MidContainer>
  ) : null;
};

export default SingleImage;

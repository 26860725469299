/* Built In Imports */
/* External Imports */
import { Table, Tbody, Td, Tr } from '@chakra-ui/react';
import parser from 'html-react-parser';
import { nanoid } from 'nanoid';

/* Internal Imports */
/* Components */
import MidContainer from '@Layout/MidContainer';

/* Services */
/* Styles */

/**
 * Renders Json Table UI Component
 *
 * @param {object} sectionContent - Data from api
 * @param {string} region - Region of Page
 * @param {string} lang - Language of Page
 * @returns {ReactElement} Json Table UI component.
 */

const JsonTableUI = ({ sectionContent, region, lang }) => {
  // const isMobile = useMediaQuery({ maxWidth: 767 });

  return (
    <MidContainer maxW={'768'}>
      <Table variant="whiteAlpha" className="JsonTable">
        <Tbody fontFamily="FedraSerifAStdBook">
          {sectionContent &&
            sectionContent.data &&
            Object.keys(sectionContent.data)?.map((item, index) => {
              return (
                <Tr p="0.35em" fontSize="18px" fontWeight="550" key={nanoid()}>
                  <Td
                    fontWeight={index > 0 ? '100' : '600'}
                    borderBottom={index > 0 ? '' : '1px solid #A9392D'}
                    borderBottomStyle={{ margin: '15px' }}
                    color={index > 0 ? '#49423B' : '#65000F'}
                    fontSize={{
                      base: index > 0 ? '' : '16px',
                      md: index > 0 ? '' : '32px',
                    }}
                    lineHeight={index > 0 ? '' : "base:'160%',md:'160%'"}
                    width={{ base: '104px', md: '223px' }}
                    textAlign={index > 0 ? '' : 'left'}
                    px="0"
                  >
                    {parser(item)}
                  </Td>
                  <Td></Td>
                  <Td
                    fontWeight={index > 0 ? '100' : '600'}
                    borderBottom={index > 0 ? '' : '1px solid #A9392D'}
                    color={index > 0 ? '#49423B' : '#65000F'}
                    fontSize={{
                      base: index > 0 ? '' : '16px',
                      md: index > 0 ? '' : '32px',
                    }}
                    lineHeight={index > 0 ? '' : "base:'160%',md:'160%'"}
                    width={{ base: '209px', md: '403px' }}
                    textAlign={index > 0 ? '' : 'left'}
                    px="0"
                  >
                    {parser(sectionContent?.data[item])}
                  </Td>
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </MidContainer>
  );
};

export default JsonTableUI;

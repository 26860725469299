/* Built In Imports */
/* External Imports */
import { Box, Heading } from '@chakra-ui/react';

/* Internal Imports */

/* Styles */
/* Services */

/**
 * Renders BlogTableOfContentsSection Component
 *
 * @param {object} sectionContent
 * @returns {ReactElement} BlogTableOfContentsSection Component
 */

const BlogTableOfContentsSection = ({ sectionContent }) => {
  return (
    <>
      <Box
        pos="relative"
        mb={{ base: '10px', md: '0' }}
        maxW="696px"
        width="100%"
        m="0 auto"
      >
        <Heading
          as="h3"
          mt="40px"
          color="#65000F"
          id={`${sectionContent?.key}`}
        >
          {sectionContent?.title}
        </Heading>
      </Box>
    </>
  );
};

export default BlogTableOfContentsSection;

/* Built In Imports */
/* External Imports */
import { Box, Link, ListItem, OrderedList } from '@chakra-ui/react';
import { nanoid } from 'nanoid';

/* Internal Imports */

/* Styles */
/* Services */

/**
 * Renders BlogTableOfContentIndex Component
 *
 * @param {object} sectionContent
 * @returns {ReactElement} BlogTableOfContentIndex Component
 */

const BlogTableOfContentIndex = ({ sectionContent }) => {
  return (
    <Box
      pos="relative"
      mb={{ base: '10px', md: '0' }}
      fontFamily="FedraSerifAStdBook"
      maxW="667px"
      width="100%"
      m="0 auto"
    >
      <Box
        w={{ base: '342px', md: '696px' }}
        // marginTop='400px'
        color="#65000F"
        fontSize={{ base: '26px', md: '32px' }}
        lineHeight="140%"
        fontWeight="600"
        as="h3"
      >
        {sectionContent?.title}
      </Box>
      <OrderedList>
        {sectionContent?.tableItems
          ? Object?.keys(sectionContent?.tableItems)?.map(item => {
              return (
                <ListItem
                  key={nanoid()}
                  p="0.35em"
                  marginLeft={{ md: '5px', base: '5px' }}
                  fontFamily="'FedraSerifAStdBook'"
                  fontSize={{ base: '16px', md: '15px' }}
                  lineHeight={{ base: '160%', md: '160%' }}
                  fontWeight="350"
                  textDecoration="underline"
                  color="#C65A28"
                  marginTop="16px"
                >
                  <Link
                    fontSize={{ base: '16px', md: '20px' }}
                    href={`#${sectionContent.tableItems[item]}`}
                  >
                    {item}
                  </Link>
                </ListItem>
              );
            })
          : null}
      </OrderedList>
    </Box>
  );
};

export default BlogTableOfContentIndex;

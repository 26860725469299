/* Built In Imports */
/* External Imports */
import { Box, Flex, Text } from '@chakra-ui/react';
import parse from 'html-react-parser';

/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';

/* Styles */
/* Services */

/**
 * Returns Quote Component
 *
 * @param sectionContent
 */

const Quote = ({ sectionContent }) => {
  return <>
    <Box maxW={1330} mx="auto" width="100%">
      <Flex
        w="100%"
        height="auto"
        maxW="904px"
        margin="40px auto"
        mx="auto"
        alignItems="center"
        pos="relative"
      >
        <Box pos="absolute" left={{ base: '-3px', md: '18.5px' }}>
          <LazyLoadImageComponent
            src={`${config.staticPath}/d/46272/1684434922-devi-website_illustrations.svg`}
            alt="devi website illustrations"
            maxHeight={{ base: '123.54px', md: '202px' }}
            height={{ base: '123.54px', md: '202px' }}
            width={{ base: '84.16px', md: '139px' }}
          />
        </Box>
        <Box
          background="quoteGradient.yellow"
          BackgroundBlendMode="multiply"
          ml={{ base: '40px', md: '80px' }}
          p={{ base: '28px 0px 29px 50px', md: '51px 26px 39px 95px' }}
        >
          <Text
            as="h6"
            fontSize={{ base: 'body1', md: 'h6' }}
            color="quoteText.color1"
            fontFamily="FedraSerifAStdBook"
            fontWeight="350"
            maxW="637px"
            opacity="0.8"
            // lineHeight={{ base: '25.2px', md: '44.29px' }}
            lineHeight={{ base: '25.2px', md: '33px' }}
          >
            {sectionContent?.quote && (
              <Box as="blockquote">
                {parse(sectionContent?.quote?.replace(/\n/g, '<span />'))}
              </Box>
            )}
          </Text>
          <style>
            {`
            blockquote span {
              display: block;
              margin: 20px 0 0;
            }
            blockquote p {
              font-size: inherit !important;
              line-height: inherit !important;
              color: inherit !important;
            }
          `}
          </style>
        </Box>
      </Flex>
    </Box>
  </>;
};

export default Quote;

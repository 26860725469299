/* Built In Imports */

/* External Imports */
import { Box, Heading } from '@chakra-ui/react';
import { render } from 'datocms-structured-text-to-html-string';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import StructuredTextContent from '@lb/Common/StructuredTextContent';

/* Styles */
/* Services */

/**
 * Renders BlogPoem Component
 *
 * @param {object} Card
 * @returns {ReactElement} BlogPoem Component
 */

const BlogPoem = ({ sectionContent }) => {
  return (
    <>
      <Box
        display="flex"
        textAlign="center"
        m={{ base: '40px auto', md: '40px auto 60px auto' }}
        w={{ base: '344px', md: '696px' }}
        fontWeight="350"
        flexDirection={'column'}
        alignItems="center"
        maxW="696px"
      >
        {sectionContent.showSeparatorAbovePoem && (
          <LazyLoadImageComponent
            src={`${config.staticPath}/d/46272/1684435675-poem-separator.png`}
            h="97px"
            w="195px"
            alt="Poem Separator"
          />
        )}

        {sectionContent.title && (
          <Heading as="h3" mt="40px" color="#65000F">
            {sectionContent.title}
          </Heading>
        )}

        {render(sectionContent?.poem) &&
          render(sectionContent?.poem) !== '<p></p>' && (
            <Box
              fontSize={{ base: '16px', md: '20px' }}
              fontFamily="FedraSerifAStdBook"
              color="#49423B"
              width={{ base: '342px', sm: '220px', md: '428px' }}
              height="auto"
              lineHeight={{ base: '160%', md: '160%' }}
            >
              {StructuredTextContent(sectionContent.poem)}
            </Box>
          )}
        {sectionContent.showSadhguruSignature && (
          <LazyLoadImageComponent
            src={`${config.staticPath}/d/46272/1684435565-sadhguru-sign.png`}
            h="84px"
            w="178px"
            mt="40px"
            alt="Sadhguru signature"
          />
        )}
      </Box>
    </>
  );
};

export default BlogPoem;
